* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.mobile-layout {
    margin: 24px 6px 0;
}

.site-layout-background {
    padding: 14px;
}

.calendar-padding {
    padding: 24px;
}

.scroll {
    overflow-y: scroll;
}

@media screen and (max-width: 425px) {
    .mobile-layout {
        margin: 0;
        padding: 0;
    }

    .site-layout-background {
        padding: 0;
    }

    .calendar-padding {
        padding: 12px 0;
    }
}

.divButtons {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 5px;
}

.rowButtons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    width: 100%;
    gap: 5px;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.buttonsPage {
    height: 100%;
}

.basicButton {
    display:block;
    padding: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    color:#c0c0c0;
    font-size:1.5em;
    font-weight: 400;
    background-color:#2f2f2f;
    border: #000 1px solid;
    border-radius:5px;
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    text-align: center;
    text-decoration: none;
    text-shadow:#000 0px -1px 0px;
    box-shadow: inset rgba(255,255,255,0.1) 0px 1px 0px, inset rgba(0,0,0,0.2) 0px -2px 2px;
    -webkit-box-shadow: inset rgba(255,255,255,0.1) 0px 1px 0px, inset rgba(0,0,0,0.2) 0px -2px 2px;
    -moz-box-shadow: inset rgba(255,255,255,0.1) 0px 1px 0px, inset rgba(0,0,0,0.2) 0px -2px 2px;
    background-image:-moz-linear-gradient(180deg, #404040 0%, #313234 40%, #2f2f2f 100%);
    background-image:-webkit-linear-gradient(180deg, #404040 0%, #313234 40%, #2f2f2f 100%);
    background-image:linear-gradient(180deg, #404040 0%, #313234 40%, #2f2f2f 100%);
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    cursor: pointer;
}

.basicButton:active{
    box-shadow: inset rgba(0,0,0,0.5) 0px 2px 8px;
    background-image:-moz-linear-gradient(180deg, #2f2f2f 0%, #363636 100%);
    background-image:-webkit-linear-gradient(180deg, #2f2f2f 0%, #363636 100%);
    background-image:linear-gradient(180deg, #2f2f2f 0%, #363636 100%);
}

.numberButton {
    display:block;
    width:140px;
    height:100px;
    padding:18px 0 12px;
    color:#c0c0c0;
    font-family: "Myriad Pro", Arial, sans-serif;
    font-size:2em;
    font-weight: 400;
    letter-spacing: -2px;
    background-color:#2f2f2f;
    border: #000 1px solid;
    border-radius:5px;
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    text-align: center;
    text-decoration: none;
    text-shadow:#000 0px -1px 0px;
    box-shadow: inset rgba(255,255,255,0.1) 0px 1px 0px, inset rgba(0,0,0,0.2) 0px -2px 2px;
    -webkit-box-shadow: inset rgba(255,255,255,0.1) 0px 1px 0px, inset rgba(0,0,0,0.2) 0px -2px 2px;
    -moz-box-shadow: inset rgba(255,255,255,0.1) 0px 1px 0px, inset rgba(0,0,0,0.2) 0px -2px 2px;
    background-image:-moz-linear-gradient(0deg, #363636 0%, #313234 40%, #2f2f2f 100%);
    background-image:-webkit-linear-gradient(0deg, #363636 0%, #313234 40%, #2f2f2f 100%);
    background-image:linear-gradient(0deg, #363636 0%, #313234 40%, #2f2f2f 100%);
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    cursor: pointer;
}

.numberButton:active{
    box-shadow: inset rgba(0,0,0,0.5) 0px 2px 8px;
    background-image:-moz-linear-gradient(0deg, #2f2f2f 0%, #363636 100%);
    background-image:-webkit-linear-gradient(0deg, #2f2f2f 0%, #363636 100%);
    background-image:linear-gradient(0deg, #2f2f2f 0%, #363636 100%);
}

.loginForm {
    background: #29292a;
    height: 100%;
    padding: 30px;
}

.nifInput {
    width: 100%;
    height: 100%;
    border: 0;
    background: transparent;
    border-radius: 0;
    font-size: 24px;
    line-height: 1.5;
    color: black;
    padding: 0;
}

.programButton {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    width:220px;
    height:100px;
    margin: 5px 5px;
    padding:15px 20px;
    color:#c0c0c0;
    text-align: right;
    font-size: 1.5em;
    letter-spacing: 3px;
    border:#000 1px solid;
    text-transform: capitalize;
    border-radius:7px;
    -webkit-border-radius:7px;
    -moz-border-radius:7px;
    box-shadow:rgba(0,0,0,1) 0px 1px 4px, inset rgba(225,225,225,0.3) 0px -2px 4px -2px;
    -webkit-box-shadow:rgba(0,0,0,1) 0px 1px 4px, inset rgba(225,225,225,0.3) 0px -2px 4px -2px;
    -moz-box-shadow:rgba(0,0,0,1) 0px 1px 4px, inset rgba(225,225,225,0.3) 0px -2px 4px -2px;
    background-image: -moz-linear-gradient(0deg, rgba(62,62,62,1) 0%, rgba(48,48,48,1) 100%);
    background-image: -webkit-linear-gradient(0deg, rgba(62,62,62,1) 0%, rgba(48,48,48,1) 100%);
    background-image: linear-gradient(0deg, rgba(62,62,62,1) 0%, rgba(48,48,48,1) 100%);
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
}

/*uppercase*/
.exerciseCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:220px;
    height:100px;
    margin: 5px 5px;
    padding:5px 5px;
    color:white;
    text-align: center;
    text-transform: capitalize;
    font-size: 1em;
    letter-spacing: 3px;
    border:#000 1px solid;
    border-radius:7px;
    -webkit-border-radius:7px;
    -moz-border-radius:7px;
    box-shadow: inset rgba(0,0,0,1) 0px 1px 4px, inset rgba(225,225,225,0.3) 0px -2px 4px -2px;
    -webkit-box-shadow: inset rgba(0,0,0,1) 0px 1px 4px, inset rgba(225,225,225,0.3) 0px -2px 4px -2px;
    -moz-box-shadow: inset rgba(0,0,0,1) 0px 1px 4px, inset rgba(225,225,225,0.3) 0px -2px 4px -2px;
    background-image: -moz-linear-gradient(0deg, rgba(91, 134, 86,1) 10%, rgba(62,62,62,1) 0%);
    background-image: -webkit-linear-gradient(0deg, rgba(91, 134, 86,1) 10%, rgba(62,62,62,1) 0%);
    background-image: linear-gradient(0deg, rgb(91, 134, 86) 10%, rgba(62,62,62,1) 0%);
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
}


/*.programButton {*/
/*    height: 100px;*/
/*    width: 180px;*/
/*    margin: 10px;*/
/*    border-radius: 10px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    font-size: 18px;*/

/*    box-shadow:*/
/*            12px 12px 16px 0 rgba(255, 255, 255, 0.3) inset,*/
/*            -8px -8px 12px 0 rgba(0, 0, 0, .25) inset;*/
/*}*/

.nifForm {
    width:430px;
    height:95px;
    margin: 12px auto 30px;
    padding:15px 20px;
    color:#c0c0c0;
    text-align: right;
    font-size: 3em;
    letter-spacing: 3px;
    overflow:hidden;
    border:#000 1px solid;
    border-radius:7px;
    -webkit-border-radius:7px;
    -moz-border-radius:7px;
    box-shadow:inset rgba(0,0,0,1) 0px 1px 4px, inset rgba(225,225,225,0.3) 0px -2px 4px -2px;
    -webkit-box-shadow:inset rgba(0,0,0,1) 0px 1px 4px, inset rgba(225,225,225,0.3) 0px -2px 4px -2px;
    -moz-box-shadow:inset rgba(0,0,0,1) 0px 1px 4px, inset rgba(225,225,225,0.3) 0px -2px 4px -2px;
    background-image: -moz-linear-gradient(0deg, rgba(62,62,62,1) 0%, rgba(48,48,48,1) 100%);
    background-image: -webkit-linear-gradient(0deg, rgba(62,62,62,1) 0%, rgba(48,48,48,1) 100%);
    background-image: linear-gradient(0deg, rgba(62,62,62,1) 0%, rgba(48,48,48,1) 100%);
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
}

.rbc-allday-cell {
    display: none;
}
.rbc-time-view .rbc-header {
    border-bottom: none;
}

.content-login{
    padding: 0 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.content-login-form {
    background: #262626;
    padding: 24px;
    height: 180px;
    width: 400px;
    border-radius: 10px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
}

.input-login {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #c0c0c0;
    padding: 0 10px;
    margin-bottom: 10px;
    font-size: 1.2em;
}

.submit-login {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #c0c0c0;
    background: #262626;
    color: #c0c0c0;
    font-size: 1.2em;
    cursor: pointer;
}


/*#LIGHT*/
.background-main-light {
    background: #222 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANklEQVQYV2NkwAMEBQX/M+KSB0m+f/+eEasCmCRIM4YCZEkMBeiSKAqwScIV4JIEK8AnCVIAAKedHTuzxDwjAAAAAElFTkSuQmCC) repeat;
}

.background-sub-light {
    background: linear-gradient(145deg, rgba(235,235,235,1) 100%, rgba(122,122,122,0.2945553221288515) 50%, rgba(64,64,64,0.50) 50%);
}

.logo-div-light {
    color: #222;
}

.loginForm-light {
    background: #d1d1d1;
    height: 100%;
    padding: 30px;
}

.nifForm-light {
    width:430px;
    height:95px;
    margin: 12px auto 30px;
    padding:15px 20px;
    color:#2a2a2a;
    text-align: right;
    font-size: 3em;
    letter-spacing: 3px;
    overflow:hidden;
    border:#000 1px solid;
    border-radius:7px;
    -webkit-border-radius:7px;
    -moz-border-radius:7px;
    box-shadow:inset rgba(0,0,0,1) 0px 1px 4px, inset rgba(225,225,225,0.3) 0px -2px 4px -2px;
    -webkit-box-shadow:inset rgba(0,0,0,1) 0px 1px 4px, inset rgba(225,225,225,0.3) 0px -2px 4px -2px;
    -moz-box-shadow:inset rgba(0,0,0,1) 0px 1px 4px, inset rgba(225,225,225,0.3) 0px -2px 4px -2px;
    background-image: -moz-linear-gradient(0deg, rgb(173, 173, 173) 0%, rgb(214, 214, 214) 100%);
    background-image: -webkit-linear-gradient(0deg, rgb(173, 173, 173) 0%, rgb(214, 214, 214) 100%);
    background-image: linear-gradient(0deg, rgb(173, 173, 173) 0%, rgb(214, 214, 214) 100%);
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
}

.numberButton-light {
    display:block;
    width:140px;
    height:100px;
    padding:18px 0 12px;
    color:#000000;
    font-family: "Myriad Pro", Arial, sans-serif;
    font-size:2em;
    font-weight: 400;
    letter-spacing: -2px;
    background-color:#616161;
    border: #000 1px solid;
    border-radius:5px;
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    text-align: center;
    text-decoration: none;
    text-shadow:rgb(171, 171, 171) 0px -1px 0px;
    box-shadow: inset rgba(255,255,255,0.3) 0px 1px 0px, inset rgba(0,0,0,0.2) 0px -2px 2px;
    -webkit-box-shadow: inset rgba(255,255,255,0.3) 0px 1px 0px, inset rgba(0,0,0,0.2) 0px -2px 2px;
    -moz-box-shadow: inset rgba(255,255,255,0.3) 0px 1px 0px, inset rgba(0,0,0,0.2) 0px -2px 2px;
    background-image:-moz-linear-gradient(0deg, #a0a0a0 0%, #c5c5c5 40%, #d0d0d0 100%);
    background-image:-webkit-linear-gradient(0deg, #a0a0a0 0%, #c5c5c5 40%, #d0d0d0 100%);
    background-image:linear-gradient(0deg, #a0a0a0 0%, #c5c5c5 40%, #d0d0d0 100%);
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    cursor: pointer;
}

.text-color-light {
    color: #222;
}

.basicButton-light {
    display:block;
    padding: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    color:#1e1e1e;
    font-size:1.5em;
    font-weight: 400;
    background-color:#2f2f2f;
    border: #000 1px solid;
    border-radius:5px;
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    text-align: center;
    text-decoration: none;
    text-shadow:rgb(163, 163, 163) 0px -1px 0px;
    box-shadow: inset rgba(255,255,255,0.1) 0px 1px 0px, inset rgba(0,0,0,0.2) 0px -2px 2px;
    -webkit-box-shadow: inset rgba(255,255,255,0.1) 0px 1px 0px, inset rgba(0,0,0,0.2) 0px -2px 2px;
    -moz-box-shadow: inset rgba(255,255,255,0.1) 0px 1px 0px, inset rgba(0,0,0,0.2) 0px -2px 2px;
    background-image:-moz-linear-gradient(180deg, #cccccc 0%, #d7d7d7 40%, #e6e6e6 100%);
    background-image:-webkit-linear-gradient(180deg, #cccccc 0%, #d7d7d7 40%, #e6e6e6 100%);
    background-image:linear-gradient(180deg, #cccccc 0%, #d7d7d7 40%, #e6e6e6 100%);
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    cursor: pointer;
}

.programButton-light {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    width:220px;
    height:100px;
    margin: 5px 5px;
    padding:15px 20px;
    color:#101010;
    text-align: right;
    font-size: 1.5em;
    letter-spacing: 3px;
    border:#000 1px solid;
    text-transform: capitalize;
    border-radius:7px;
    -webkit-border-radius:7px;
    -moz-border-radius:7px;
    box-shadow:rgba(0,0,0,1) 0px 1px 4px, inset rgba(225,225,225,0.3) 0px -2px 4px -2px;
    -webkit-box-shadow:rgba(0,0,0,1) 0px 1px 4px, inset rgba(225,225,225,0.3) 0px -2px 4px -2px;
    -moz-box-shadow:rgba(0,0,0,1) 0px 1px 4px, inset rgba(225,225,225,0.3) 0px -2px 4px -2px;
    background-image: -moz-linear-gradient(0deg, rgb(192, 192, 192) 0%, rgb(226, 226, 226) 100%);
    background-image: -webkit-linear-gradient(0deg, rgb(192, 192, 192) 0%, rgb(226, 226, 226) 100%);
    background-image: linear-gradient(0deg, rgb(192, 192, 192) 0%, rgb(226, 226, 226) 100%);
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
}

.exerciseCard-light {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:220px;
    height:100px;
    margin: 5px 5px;
    padding:5px 5px;
    color:black;
    text-align: center;
    text-transform: capitalize;
    font-size: 1em;
    letter-spacing: 3px;
    border:#000 1px solid;
    border-radius:7px;
    -webkit-border-radius:7px;
    -moz-border-radius:7px;
    /* box-shadow: inset rgba(0,0,0,1) 0px 1px 1px, inset rgba(225,225,225,0.3) 0px -1px 1px -1px;
    -webkit-box-shadow: inset rgba(0,0,0,1) 0px 1px 4px, inset rgba(225,225,225,0.3) 0px -2px 4px -2px;
    -moz-box-shadow: inset rgba(0,0,0,1) 0px 1px 4px, inset rgba(225,225,225,0.3) 0px -2px 4px -2px; */
    background-image: -moz-linear-gradient(0deg, rgb(91, 134, 86) 10%, rgb(214, 214, 214) 0%);
    background-image: -webkit-linear-gradient(0deg, rgb(91, 134, 86) 10%, rgb(214, 214, 214) 0%);
    background-image: linear-gradient(0deg, rgb(91, 134, 86) 10%, rgb(214, 214, 214) 0%);
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
}

.text-shadow-light {
    /* text-shadow: #9fa9ae 1px 1px,#9fa9ae -0px 0px,#9fa9ae -1px 1px,#9fa9ae -2px 2px,#9fa9ae -3px 3px,#9fa9ae -4px 4px,#9fa9ae -5px 5px,#9fa9ae -6px 6px,#9fa9ae -7px 7px,#9fa9ae -8px 8px,#9fa9ae -9px 9px; */
    color: #222;
}

.squeeze-color-light {
    color: #000000;
    text-shadow: none;
}

.text-submit-light {
    color: green;
}


/*#DARK*/

.background-main{
    background: #222 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANklEQVQYV2NkwAMEBQX/M+KSB0m+f/+eEasCmCRIM4YCZEkMBeiSKAqwScIV4JIEK8AnCVIAAKedHTuzxDwjAAAAAElFTkSuQmCC) repeat;
}

.background-sub {
    background: linear-gradient(145deg, rgba(255,255,255,0.19371498599439774) 100%, rgba(122,122,122,0.2945553221288515) 50%, rgba(64,64,64,0.50) 50%);
}

.logo-div {
    color: white;
}

.text-color {
    color: white;
}

.text-shadow {
    text-shadow: #2A3D4E 1px 1px,#2A3D4E -0px 0px,#2A3D4E -1px 1px,#2A3D4E -2px 2px,#2A3D4E -3px 3px,#2A3D4E -4px 4px,#2A3D4E -5px 5px,#2A3D4E -6px 6px,#2A3D4E -7px 7px,#2A3D4E -8px 8px,#2A3D4E -9px 9px;
    color: white;
}

.squeeze-color {
    color: white;
}

.text-submit {
    color: lightgreen;
}

@media screen and (max-width: 768px) {
    .content-login {
        padding: 10px;
        flex-direction: column;
    }
    .content-login-form {
        width: 100%;
    }
}

/*SCALE INFO*/

.exercise-name {
    font-size: 70px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.column-info {
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 24px;
}

.column-center {
    padding: 50px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}

.column-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
}

.column-img-img {
    max-width: 320px;
    max-height: 380px;
    width: auto;
    height: auto;
}

.column-last {
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 30px;
}

/* SCALE EXERCISE*/

.column-time {
    display: flex;
    text-align: center;
    font-size: 48px;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.column-chrono {
    font-size: 62px;
}

.bar-overflow {
    position: relative;
    overflow: hidden;
    width: 920px;
    height: 460px;
}

.radial-separators {
    position: absolute;
    top: 0;
    left: 458px;
    width: 100%;
    height: 200%;
}

.main-chrono {
    position: absolute;
    font-size: 190px;
    line-height: 0.8;
    color: red;
    top: 50%;
    font-weight: bold;
    text-shadow: #2A3D4E 1px 1px,#2A3D4E -0px 0px,#2A3D4E -1px 1px,#2A3D4E -2px 2px,#2A3D4E -3px 3px,#2A3D4E -4px 4px,#2A3D4E -5px 5px,#2A3D4E -6px 6px,#2A3D4E -7px 7px,#2A3D4E -8px 8px,#2A3D4E -9px 9px;
}

.squeeze-text {
    font-size: 120px;
    margin-top: 20px;
}

.column-reps {
    display: flex;
    text-align: center;
    font-size: 56px;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.reps-text {
    font-size: 86px;
}

.column-exercise-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    text-align: center;
    padding: 20px;
    font-size: 30px;
}

.column-exercise-img {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    color: white;
    padding: 20px;
    width: 300px;
}

.column-exercise-img-img {
    max-width: 300px;
    max-height: 110px;
}

.column-exercise-last {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    text-align: center;
    padding: 20px;
    font-size: 30px;
    line-height: 1;
}

@media screen and (max-width: 1024px) {
    .exercise-name {
        font-size: 50px;
    }

    .column-info {
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 18px;
    }

    .column-center {
        padding: 40px;
    }

    .column-img {
        height: 200px;
    }

    .column-img-img {
        max-width: 220px;
        max-height: 280px;
    }

    .column-last {
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 24px;
    }

    .column-time {
        font-size: 36px;
    }

    .column-chrono {
        font-size: 48px;
    }

    .bar-overflow {
        width: 720px;
        height: 360px;
    }

    .radial-separators {
        left: 358px;
    }

    .main-chrono {
        font-size: 140px;
    }

    .squeeze-text {
        font-size: 80px;
    }

    .column-reps {
        font-size: 36px;
    }

    .reps-text {
        font-size: 56px;
    }

    .column-exercise-info {
        font-size: 24px;
        padding-top: 0;
    }

    .column-exercise-img {
        padding-top: 0;
    }

    .column-exercise-img-img {
    }

    .column-exercise-last {
        font-size: 24px;
        padding-top: 0;
    }
}

.step-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
}

.ant-btn:hover, .ant-btn:focus {
    color: #000;
    text-shadow: 2px 2px 5px #00000050;
}